import PropTypes from 'prop-types';
import React, {
  useRef, useContext, useMemo,
  useState,
  useEffect,
} from 'react';

import { StickyNav } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useIsWrapperMinimized, useSeason } from '@powdr/hooks';
import { getActiveLinkAtSetDepth, isBuildTime } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { HeaderLogo, DorToggle } from './components';
import {
  StyledHeader, NavigationDrawerToggle,
  HeaderLayoutWrapper, DrawerText,
  HeaderLeft, HeaderCenter, HeaderRight,
  NavigationDrawerToggleIcon,
  LoginLinkIcon,
  LoginLinkWrapper,
  LoginLinkText,
} from './styles';

export const Header = ({
  levels,
  showStickyNav,
  isPageModal,
}) => {
  const {
    setDrawerOpen,
    setNavigationDrawerOpen,
    setDorDrawerOpen,
    isMobile,
  } = useContext(AppContext);
  const {
    navigationDrawer: {
      navigation,
      loginLink,
    },
    headerConfig,
  } = useStaticData();
  const headerRef = useRef(null);
  const isWrapperMinimized = useIsWrapperMinimized();
  const isScrolled = (!isBuildTime()) ? (window.scrollY > 0) : false;
  const { checkSeasonality } = useSeason();
  const isHeaderMinimizedByDefault = isPageModal === true || showStickyNav === true;
  const [isMinimizeHeader, setMinimizeHeader] = useState(false);
  const [headerOpacity, setHeaderOpacity] = useState(headerConfig?.headerOpacityUnminimized);
  const handleMinimize = useMemo(() => {
    if (isMobile) {
      if (isHeaderMinimizedByDefault && !isScrolled) return false;
      return isWrapperMinimized;
    }

    if (isHeaderMinimizedByDefault) return true;
    return isWrapperMinimized;
  }, [isScrolled, isMobile, isWrapperMinimized, isHeaderMinimizedByDefault]);

  const handleHeaderOpacity = useMemo(() => {
    if (isMobile) {
      if (isScrolled || (isHeaderMinimizedByDefault && !isScrolled)) {
        return headerConfig?.headerOpacityMinimized;
      }
    }
    if (isScrolled || isHeaderMinimizedByDefault) return headerConfig?.headerOpacityMinimized;
    return headerConfig?.headerOpacityUnminimized;
  }, [isScrolled, isMobile, headerConfig, isHeaderMinimizedByDefault]);

  useEffect(() => {
    setMinimizeHeader(handleMinimize);
    setHeaderOpacity(handleHeaderOpacity);
  }, [handleMinimize, handleHeaderOpacity]);

  const stickyNav = useMemo(
    () => getActiveLinkAtSetDepth(navigation?.childPages, levels, 1)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, navigation],
  );

  const pageNav = useMemo(
    () => getActiveLinkAtSetDepth(stickyNav, levels, 2)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, stickyNav],
  );

  return (
    <StyledHeader
      ref={headerRef}
      opacity={headerOpacity}
      minimizeWrapper={isMinimizeHeader}
    >
      <HeaderLayoutWrapper
        minimizeWrapper={isMinimizeHeader}
      >
        {(headerConfig?.isDorFeedEnabled) && (
          <HeaderLeft>
            <DorToggle
              onClick={() => setDrawerOpen(true, setDorDrawerOpen)}
            />
          </HeaderLeft>
        )}
        <HeaderCenter isLeftJustified={!headerConfig?.isDorFeedEnabled}>
          <HeaderLogo
            isMinimizedLogo={(headerOpacity === headerConfig?.headerOpacityMinimized)}
          />
        </HeaderCenter>
        <HeaderRight>
          {(loginLink?.href) && (
            <LoginLinkWrapper
              isWrapperMinimized={isMinimizeHeader}
              isScrolled={(isMobile) && isScrolled}
              href={loginLink?.href}
              target={loginLink?.target}
            >
              <LoginLinkIcon name="content-headshot" />
              <LoginLinkText>
                Log In
              </LoginLinkText>
            </LoginLinkWrapper>
          )}
          <NavigationDrawerToggle
            id="open-navigation-drawer" // do not change, needed for tracking
            title="Open Navigation Drawer"
            isWrapperMinimized={isMinimizeHeader}
            isScrolled={(isMobile) && isScrolled}
            onClick={() => setDrawerOpen(true, setNavigationDrawerOpen)}
          >
            <NavigationDrawerToggleIcon name="ui-menu" />
            <DrawerText>Menu</DrawerText>
          </NavigationDrawerToggle>
        </HeaderRight>
      </HeaderLayoutWrapper>
      {(showStickyNav) && (
        <StickyNav
          levels={levels}
          data={stickyNav}
          mobileInnerNavData={pageNav}
          removeSticky
        />
      )}
    </StyledHeader>
  );
};

Header.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStickyNav: PropTypes.bool,
  isPageModal: PropTypes.bool,
};

Header.defaultProps = {
  showStickyNav: false,
  isPageModal: false,
};
